<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/3">
					<h6 class="w-1/1">딜 수락일</h6>
					<div class="flex">
						<datePicker placeholder="from" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker placeholder="to" model="opt.endDate" />
					</div>
				</div>
				<div class="w-1/6 pl-4">
					<h6>배송 상태</h6>
					<vSelect
						class="w-1/1"
						v-model="opt.orderStatus"
						:clearable="false"
						:options="
							deliveryStatusOpts.filter(v => {
								switch (v.value) {
									case '':
									case '2':
									case '6':
									case '7':
									case '18':
									case '20':
										return true
								}
								return false
							})
						"
					/>
				</div>
				<div class="w-1/6 px-4">
					<h6>딜 요청 상태</h6>
					<vSelect v-model="opt.dealStatus" :clearable="false" :options="statusOpts" />
				</div>
				<div class="w-1/2">
					<h6>검색어</h6>
					<div class="flex">
						<vSelect class="w-90" v-model="opt.searchKeyName" :clearable="false" :options="keywordOpts" />
						<b-form-input
							class="mx-4"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<b-button class="float-right ml-4" @click="insertTrackingNumber" variant="gray">운송장 일괄등록</b-button>
			<b-button class="float-right ml-4" @click="excelExport">엑셀 다운로드 요청 </b-button>
			<b-button class="float-right" @click="downloadOrderForm" variant="info">협찬 발주서 다운로드</b-button>

			<tb class="mt-14" :inf="inf" :res="res" checkBoxKey="dealRequestIdx" />
		</b-card>
		<insertTrackingNumberPopup :p="ths" type="sponsor" />
	</div>
</template>

<script>
import insertTrackingNumberPopup from 'comp/local/insertTrackingNumberPopup'

const now = new Date(),
	//딜 요청 상태
	statusOpts = [
		{ label: '전체', value: '' },
		{ label: '딜 수락', value: 'O' },
		{ label: '핫트 관리자 취소', value: 'F' },
		{ label: '핫티스트 취소', value: 'C' },
		{ label: '파트너사 취소', value: 'P' },
	],
	keywordOpts = [
		{ label: '핫트 아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '딜 요청 번호', value: 'dealRequestIdx' },
		{ label: '딜 번호', value: 'openDealNum' },
		{ label: '상품명', value: 'productName' },
	],
	defOpt = {
		startDate: getMonth(now, -1),
		endDate: now,

		dealStatus: statusOpts[0],
		searchKeyName: keywordOpts[0],
		searchKeyword: '',
	}

export default {
	name: 'keep',
	components: { insertTrackingNumberPopup },
	data() {
		return {
			statusOpts,
			keywordOpts,
			deliveryStatusOpts: [],

			opt: { ...defOpt },
			defOpt,

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{
					title: '핫트아이디',
					key: 'hottId',
					size: 3,
					sortable: 1,
				},
				{
					title: '이름',
					key: 'memberName',
					size: 2,
				},
				{
					title: '요청 번호',
					model: 'button',
					size: 2,
					text: item => item.dealRequestIdx,
					bVariant: 'no',
					class: 'text-primary',
					func: item => page('orderSponsoredDetail', { dealRequestIdx: item.dealRequestIdx }),
				},
				{ title: '딜 번호', key: 'openDealNum', size: 2 },
				{ title: '배송 상태', key: 'orderStatus', size: 2 },
				{ title: '딜수락일', key: 'dealAcceptDay', size: 3, sortable: 1 },
				{ title: 'idx', key: 'productGroupIdx', size: 2 },
				{ title: '상품명', key: 'productName', size: 6, notCenter: 1, sortable: 1 },
				{ title: '택배사', key: 'deliveryCompany', size: 2 },
				{ title: '송장번호', key: 'invoiceNum', size: 2 },
				{ title: '딜 요청상태', key: 'isComplete', size: 2 },
				{ title: '최근 메모', key: 'memo', size: 6, notCenter: 1 },
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			if (typeof operation != 'string') operation = 'LIKE'

			const opt = this.opt,
				orderStatus = getDropdownValue(opt.orderStatus),
				dealStatus = getDropdownValue(opt.dealStatus),
				error = [],
				data = {
					orderBy: getOrder('startDate'),
					paging: getPaging(),
				}

			if (dealStatus) {
				data.dealStatus = dealStatus
				data.isAccepted = opt.dealStatus.searchAccepted
			}

			if (opt.startDate > opt.endDate) error.push('딜 수락일의 시작일이 종료일보다 큽니다')
			else {
				data.startDate = get_time('d', 0, '', opt.startDate)
				data.endDate = get_time('d', 0, '', opt.endDate)
			}

			if (orderStatus) data.orderStatus = orderStatus * 1

			if (opt.searchKeyword)
				data.searchKey = getSearchKey(getDropdownValue(opt.searchKeyName), opt.searchKeyword, operation)

			if (error.length) alert.w(error)
			else {
				this.tb.clearChecked()
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/sponsorSelect/sponsoredProductList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						return v
						//v.requestIdx = v.idx
						v.dealStatus = v.statusName

						if (!v.dealName) v.dealName = `${v.brandName} ${v.productGroupName}`

						v.name = v.memberName
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()
			ex.date(opt.startDate, opt.endDate, '딜 수락일')
			ex.dropdown(opt.orderStatus, this.deliveryStatusOpts, '배송상태')
			ex.dropdown(opt.dealStatus, statusOpts, '딜 요청상태')
			ex.search(opt.searchKey, keywordOpts)

			ex.go(this)
		},
		downloadOrderForm() {
			if (this.$acl.get[0] != 'ROLE_DELIVERY' && this.$acl.get[0] != 'ROLE_ADMIN')
				return alert.w('다운로드 권한이 없습니다.')

			const ex = new excel('협찬 발주서 다운로드')
			postApi('/api/backoffice/sponsorHandle/downloadOrderForm', { excelInfo: ex.get() }).then(() =>
				alert.excel()
			)
		},
		insertTrackingNumber() {
			this.insertTrackingNumberPopup.open()
		},
	},
	created() {
		getConfig('delivery_status', false).then(res => {
			this.deliveryStatusOpts = res.delivery_status
			defOpt.orderStatus = this.deliveryStatusOpts[0]

			this.opt = { ...defOpt }
			this.search()
		})
	},
}
</script>
